import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

function Redirect() {
  const { uniqueCode } = useParams();

  useEffect(() => {
    fetch(`https://api.flipteria.lk/api/v2/urlShortener/${uniqueCode}`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          window.location.href = "https://flipteria.lk/notfound";
          return;
        } else {
          return response.json();
        }
      })
      .then((data) => {
        window.location.href = data.longUrl;
      })
      .catch((err) => {
        window.location.href = `https://flipteria.lk/notfound`;
      });
  }, [uniqueCode]);

  return <div></div>;
}

export default Redirect;
